<template>
  <div class="browser-guide">
    <div class="tip-text">
      <template v-if="browserIncluded">
        <p>对不起，您当前浏览器的版本太低了</p>
        <p>
          浏览器版本太低会导致页面无法访问，建议您将浏览器升级到最新版本
        </p>
        <p>或者</p>
        <strong>点击图标下载浏览器，安装后记得在新浏览器打开本系统哟~</strong>
      </template>
      <template v-else>
        <p>
          对不起，系统暂时不支持当前浏览器，建议您选择以下推荐的浏览器
        </p>
        <strong>点击图标即可前往下载，安装后记得在新浏览器打开本系统哟~</strong>
      </template>
    </div>
    <div class="out-case">
      <p class="hint-title">推荐浏览器</p>
      <div class="browsers-case">
        <a
          v-for="bro in browsers"
          :key="bro.link"
          target="_blank"
          class="browser-item"
          @click.stop="downloadBrowser($event, bro.link)"
        >
          <img :src="bro.logo" />
          <p>{{ bro.title }}</p>
        </a>
      </div>
      <img src="../assets/man_hand_up.png" alt="" class="man" />
      <img src="../assets/box.png" alt="" class="box" />
    </div>
  </div>
</template>

<script>
import { getBrowserVersionInfo } from '../utils/browserTip'

export default {
  data() {
    return {
      browsers: [
        {
          title: 'QQ浏览器',
          logo: require('../assets/qq.png'),
          link: 'https://browser.qq.com/',
        },
        {
          title: '谷歌浏览器',
          logo: require('../assets/chrome.png'),
          link: 'https://www.google.cn/intl/zh-CN/chrome/',
        },
        {
          title: '搜狗浏览器',
          logo: require('../assets/sogo.png'),
          link: 'https://ie.sogou.com/',
        },
        {
          title: '360浏览器',
          logo: require('../assets/360.png'),
          link: 'https://browser.360.cn/',
        },
      ],
    }
  },
  computed: {
    currentBrowser() {
      const ua = navigator.userAgent.toLowerCase()
      const browsers = ['chrome', 'ubrowser', 'metasr', 'qqbrowser', 'trident']
      let current = 'chrome'
      browsers.forEach((item) => {
        if (ua.indexOf(item) > -1) {
          current = item
        }
      })
      return current
    },
    browserIncluded() {
      return ['chrome', 'metasr', 'qqbrowser'].includes(this.currentBrowser)
    },
  },
  methods: {
    downloadBrowser(e, link) {
      e.preventDefault()
      if (!localStorage.getItem('downloadBrowsers')) {
        localStorage.setItem('downloadBrowsers', 1)
        this.$report('浏览器引导页-下载浏览器', link, getBrowserVersionInfo())
      }
      window.open(link)
    },
  },
}
</script>
<style lang="scss" scoped>
.browser-guide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 198px;
  font-size: 18px;
  color: #666;
  .out-case {
    box-shadow: 0px 3px 11px 0px rgba(46, 68, 211, 0.15);
    background: rgba(255, 255, 255, 0.95);
    border-radius: 10px;
    padding: 30px;
    width: 544px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    .browsers-case {
      display: flex;
      justify-content: space-between;
      .browser-item {
        display: block;
        img {
          border-radius: 50%;
          width: 80px;
          height: 80px;
          box-shadow: 0px 3px 11px 0px rgba(46, 68, 211, 0.15);
          margin-bottom: 20px;
        }
      }
    }
    .man,
    .box {
      position: absolute;
      margin-top: -40px;
    }
    .man {
      left: 260px;
    }
    .box {
      bottom: -130px;
      right: 80px;
    }
  }
  .tip-text {
    margin-bottom: 20px;
    text-align: center;
  }
}
</style>
